export default class PriceCalculator {
  constructor() {
    this.basePrice = 0;
    this.nextPrice = 0;
  }

  setPrices = (base, next) => {
    this.basePrice = base;
    return this.nextPrice = next;
  }

  getPriceFor = (num) => {
    let normalCount = parseInt(num/10);
    if (num%10) { normalCount += 1; }
    const cheeperCount = num - normalCount;
    const price = (this.basePrice * normalCount) + (this.nextPrice * cheeperCount);
    return parseFloat(price).toFixed(2);
  }
}

angular.module('estimationApp').factory('PriceCalculator', () => new PriceCalculator());
