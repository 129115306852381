import '../application.js';

import $ from 'jquery';
import angular from 'angular';

angular.module('estimationApp')
.controller('TypeCtrl', ['$scope', function($scope) {
  $scope.top = 30;
  $scope.bottom = 10;
  $scope.explicitActive = true;

  $scope.changeZIndex = function(elem) {
    const zIndex = elem.css('z-index');
    if (Number(zIndex) === $scope.top) {
      return elem.css('z-index', $scope.bottom);
    } else {
      return elem.css('z-index', $scope.top);
    }
  };

  $scope.$on('toggleCards', (_,stopPropagating)=> {
    return $scope.toggleCards(stopPropagating);
  });

  return $scope.toggleCards = function(stopPropagating) {
    $('.toggle-link').toggle();
    $scope.explicitActive = !$scope.explicitActive;
    if (!stopPropagating) { $scope.$broadcast('explicitActive', $scope.explicitActive); }
    $('img.explicit').toggleClass('right-down');
    $('img.explicit').toggleClass('right-up');
    $('img.non-explicit').toggleClass('left-down');
    return $('img.non-explicit').toggleClass('left-up');
  };
}
])

.directive('toggleExplicit', () =>
  ({
    restrict: 'A',
    controller: 'TypeCtrl',
    link(scope, elem, attrs) {

      return elem.bind('click', function(event) {
        scope.toggleCards();

        return event.preventDefault();
    });
    }
  })
);
