import "../application.js";
import "../services/validator";
import "../services/price_calculator";
import "../services/braintree_client";

import $ from "jquery";
import angular from "angular";

angular.module("estimationApp").controller("PaymentsCtrl", [
  "$scope",
  "$http",
  "Validator",
  "PriceCalculator",
  "BraintreeClient",
  function ($scope, $http, Validator, PriceCalculator, PaymentsClient) {
    $scope.processing = false;
    $scope.showPayment = false;
    $scope.form = $("#braintree-payment-form");
    $scope.explicitActive = "on";
    $scope.number = 1;
    PriceCalculator.setPrices(basePrice, nextPrice);
    $scope.price = PriceCalculator.getPriceFor($scope.number);

    $scope.isExplicitActive = () => $scope.explicitActive;

    Validator.setForm($scope.form);

    $scope.isErrorFor = (field) => Validator.isErrorFor(field);

    $scope.errorsFor = (field) => Validator.errors[field];

    $scope.setPrice = function () {
      Validator.validateQuantity("quantity");
      if (!Validator.isErrorFor("quantity")) {
        return ($scope.price = PriceCalculator.getPriceFor($scope.number));
      }
    };

    $scope.getPrice = function () {
      return $scope.price;
    };

    $scope.init = () => {
      $scope.setCardsType();
      return $scope.setPayments();
    };

    $scope.setPayments = () =>
      $http.get("/client_token").then(
        (data) =>
          PaymentsClient.setup(data.data, {
            onSuccess: $scope.submitTransaction,
            onInitializationError: $scope.handleInitializationError,
            onVerifyCardError: $scope.handleVerifyCardError,
            onPaymentGeneralError: $scope.handlePaymentMethodErrors,
            onPaymentFormErrors: $scope.handlePaymentFormErrors,
            onDeviceDataReady: $scope.setDeviceData,
            bindFormSubmit: $scope.bindFormSubmit,
            getPrice: $scope.getPrice,
          }),
        (error) => $scope.handleInitializationError(error)
      );
    $scope.bindFormSubmit = (callback) => {
      $scope.form.on("submit", (e) => {
        e.preventDefault();
        callback();
      });
    };

    $scope.handleInitializationError = (error) => {
      Validator.loadInitializationError(error);
      return $scope.$apply();
    };

    $scope.handleVerifyCardError = () => {
      Validator.loadVerifyCardError();
      return $scope.$apply();
    };

    $scope.handlePaymentMethodErrors = (error) => {
      gtag("event", "click", {
        event_category: "button",
        event_label: "pay",
      });
      Validator.loadPaymentMethodError(error);
      Validator.orderValid();
      gtag("event", "bad payment method", {
        event_category: "payment",
      });
      return $scope.$apply();
    };

    $scope.handlePaymentFormErrors = (errors) => {
      gtag("event", "click", {
        event_category: "button",
        event_label: "pay",
      });
      Validator.loadPaymentFormErrors(errors);
      Validator.orderValid();
      gtag("event", "bad_payment_fields", {
        event_category: "payment",
      });
      return $scope.$apply();
    };

    $scope.setDeviceData = (data) => {
      return ($scope.deviceData = data);
    };

    $scope.setCardsType = () => {
      if (window.location.hash === "#pg") {
        $scope.explicitActive = "off";
        return $scope.toggleCards();
      }
    };

    $scope.checkShippingData = function () {
      $scope.showPayment = true;
      return ($scope.blockShipping = true);
    };

    $scope.submitTransaction = function (data) {
      if ($scope.processing) {
        return;
      }

      Validator.clear();
      gtag("event", "pay", {
        event_category: "button",
      });

      gtag("event", "got_payment_nonce", {
        event_category: "payment",
      });

      if (Validator.orderValid()) {
        return $scope.submitPayment(data.nonce);
      } else {
        $scope.$apply();
        return gtag("event", "bad_address", {
          event_category: "payment",
        });
      }
    };

    $scope.submitPayment = (nonce) => {
      $scope.$apply(() => ($scope.processing = true));
      return $.ajax({
        type: "POST",
        url: "/create_transaction",
        data: `${$scope.form.serialize()}&price=${
          $scope.price
        }&nonce=${nonce}&device_data=${$scope.deviceData}`,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .done(function (data) {
          $scope.successfullPayment = true;
          $scope.processing = false;

          // Send success event to GA4
          gtag("event", "transaction", {
            event_category: "payment",
            event_label: "success",
            value: data.amount / 100,
          });
          // Send conversion event to GA4
          gtag("event", "purchase", {
            currency: "USD",
            value: data.amount / 100,
          });
        })
        .fail(function (data) {
          $scope.errors = true;
          Validator.loadTransactionError(data.responseJSON.errors);

          if (data.status === 401) {
            // Send failure event to GA4
            gtag("event", "transaction", {
              event_category: "payment",
              event_label: "failure",
              value: data.amount / 100,
            });
          } else {
            // Send error event to GA4
            gtag("event", "error", {
              event_category: "payment",
            });
          }
        })
        .always(function () {
          $scope.processing = false;
          return $scope.$apply();
        });
    };

    $scope.$on("explicitActive", (_, explicitActive) =>
      $scope.$apply(
        () => ($scope.explicitActive = explicitActive ? "on" : "off")
      )
    );

    return ($scope.toggleCards = () => $scope.$emit("toggleCards", true));
  },
]);
