import {client, hostedFields, threeDSecure} from 'braintree-web';

export let clientCallbacks = {};
let threeDSecureInstance;

const verifyCard = (payload) => {
  threeDSecureInstance.verifyCard({
    amount: clientCallbacks.getPrice(),
    nonce: payload.nonce,
    bin: payload.details.bin,
    onLookupComplete: (_data, next) => { next() }
  }, function (err, response) {
    if (err) {
      clientCallbacks.onPaymentGeneralError(err);
      return;
    }

    if (response.threeDSecureInfo.liabilityShifted != response.threeDSecureInfo.liabilityShiftPossible) {
      clientCallbacks.onVerifyCardError();
    } else {
      clientCallbacks.onSuccess(response);
    }
  });
}

const tokenize = (hostedFieldsInstance) => {
  var state = hostedFieldsInstance.getState();
  var formValid = Object.keys(state.fields).every(function (key) {
    return state.fields[key].isValid;
  });

  if (formValid) {
    return hostedFieldsInstance.tokenize((err, payload) => {
      if (err) {
        clientCallbacks.onPaymentGeneralError(err);
        return;
      };
      verifyCard(payload);
    });
  } else {
    clientCallbacks.onPaymentFormErrors(state.fields)
  }
}

export default class BraintreeClient {
  constructor({client, hostedFields, threeDSecure}) {
    this.client = client;
    this.hostedFields = hostedFields;
    this.threeDSecure = threeDSecure;
    this.createHostedFields = this.createHostedFields.bind(this);
    this.createThreeDSecure = this.createThreeDSecure.bind(this);
    this.setup = this.setup.bind(this)
    this._doSetup = this._doSetup.bind(this)
  }

  setup = (data, callbacks) => {
    clientCallbacks = callbacks;
    return this.client.create(
      {authorization: data.token},
      this._doSetup
    )
  }

  _doSetup = (err, clientInstance) => {
    if (err) {
      clientCallbacks.onInitializationError(err);
      return;
    };
    this.createHostedFields(clientInstance);
    this.createThreeDSecure(clientInstance);
  }

  createThreeDSecure = (clientInstance) => {
    this.threeDSecure.create({
      client: clientInstance,
      version: 2
    },
    this._doCreateThreeDSecure
    )
  }

  _doCreateThreeDSecure = (err, threeDSecureInstanceReceived) => {
    if (err) {
      clientCallbacks.onInitializationError(err);
      return;
    };

    threeDSecureInstance = threeDSecureInstanceReceived;
  }

  createHostedFields = (clientInstance) => {
    this.hostedFields.create({
      client: clientInstance,
      styles: {
        input: {
          'font-size': "1.1em",
          'line-height': "1.5",
          'font-family': "'Open Sans', sans-serif"
        }
      },
      fields: {
        number: { selector: "#number" },
        expirationMonth: {
          selector: "#expiration-month",
          placeholder: 'MM'
        },
        expirationYear: {
          selector: "#expiration-year",
          placeholder: 'YYYY'
        },
        cvv: { selector: "#cvv" }
      },
    },
    (err, hostedFieldsInstance) => {
      if (err) {
        clientCallbacks.onInitializationError(err);
        return;
      };
      clientCallbacks.bindFormSubmit(() => tokenize(hostedFieldsInstance));
    });
  }
}

angular.module('estimationApp').factory('BraintreeClient', () => new BraintreeClient({client, hostedFields, threeDSecure}));
