import "../application.js";

const $ = require("jquery");
const angular = require("angular");

angular.module("estimationApp").directive("scroll", () => ({
  restrict: "A",
  link(scope, elem, attrs) {
    return elem.on("click", (e) => {
      e.preventDefault();
      scrollTo = attrs.scroll;

      $("html, body").animate(
        {
          scrollTop: $(scrollTo).offset().top,
        },
        700,
        () => {
          $(attrs.focus).focus();
          gtag("event", attrs.event, {
            event_category: "link",
            event_label: "click",
          });
        }
      );
    });
  },
}));
